html {
  background: #333;
  position: relative;
  z-index: 0;
  font-family: 'Bebas Neue', 'Helvetica Neue';
}

body::after {
  content: '';
  background: url('./assets/background.png') no-repeat top 0px left 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.flex-full-size {
  flex: 0 0 100%;
}

.navbarImage {
  width: 300px;
  top: -130px;
}